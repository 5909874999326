import React from "react";
import { MaterialDesignContent, SnackbarProvider } from "notistack";
import { styled } from "@material-ui/core";

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  "&.notistack-MuiContent-success": {
    backgroundColor: "#814DFA",
  },
  "&.notistack-MuiContent-error": {
    backgroundColor: "#970C0C",
  },
}));

export default function SnackbarProviderWrap({ children }) {
  return (
    <SnackbarProvider
      maxSnack={5}
      autoHideDuration={3000}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      Components={{
        success: StyledMaterialDesignContent,
        error: StyledMaterialDesignContent,
      }}
    >
      {children}
    </SnackbarProvider>
  );
}
