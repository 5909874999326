import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import { Provider as ReduxProvider } from 'react-redux';
import "./Styles/style.css";
import App from "./App";
import "./i18next"; //disabled i18 for now, enable back when server error 500 is fixed
import axios from "axios";
import { apiBaseUrl } from "./config";
import Loader from "./Components/Loader/Loader";
import { store } from "./redux/store";

const root = ReactDOM.createRoot(document.getElementById("root"));
axios.defaults.baseURL = apiBaseUrl; // "http://dvcareer-api.cp360apps.com/api"; // "http://career-api.cp360apps.com/api";

root.render(
  <ReduxProvider store={store}>
  <Suspense
    fallback={
      <Loader/>
    }
  >
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Suspense>
  </ReduxProvider>
);
