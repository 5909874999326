export const createAdminSlice = (set) => ({
  firstName: "",
  lastName: "",
  emailId: "",
  token: "",
  adminID: "",
  role: "",
  profile:'',
  updateAdminData: (fName, lName, email, token, ID, role,profile) => {
    // console.log("updatingAdminData: ", fName, lName, token);
    // set({ statuses: statusData });
    // console.log("updateStatuses: ", statusData);
    set({
      firstName: fName,
      lastName: lName,
      emailId: email,
      token: token,
      adminID: ID,
      role: role,
     profile:profile
    });
  },
});
