import create from "zustand";
import { immer } from "zustand/middleware/immer";
import persist from "./utils/persist";
import { createStatusSlice } from "./statusSlice";
import { createActiveIdSlice } from "./storeData";
import { createAdminSlice } from "./adminSlice";
import { titleUpdate } from "./wlcmTitle";
// import { createTimerSlice } from "./timerSlice";

export const useBoundStore = create(
  persist(
    {
      key: "auth", // required, child key of storage
    },
    (...a) => ({
      ...createStatusSlice(...a),
      ...createActiveIdSlice(...a),
      ...createAdminSlice(...a),
      ...titleUpdate(...a),
      // ...createTimerSlice(...a)
    })
  )
);
