
import "./loader.css";
// import useColorTheme from "../hooks/useColorTheme";
import { Box} from '@material-ui/core';

const Loader = () => {
//   const {color}=useColorTheme()


  return (
    <Box style={{ 
        height:'100%',
width:'100%',
        display: 'flex',
        flex:1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#ffff',
    flexDirection:'column'}}
        >
    <div class="loadingio-spinner-spinner-7ibe908d25h" style={{
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        // width: '50%'
    }}><div class="ldio-vtiyclkd51">
<div></div>
<div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
</div>
</div>
{/* <br />
<h4 style={{ color: 'black' }}>Loading...</h4> */}
</Box>
  );
};

export default Loader;
