export const COLOR_TODO = 'COLOR_TODO';
export const LOGO_TODO = 'LOGO_TODO';
export const CHECK_TODO = 'CHECK_TODO';

export const addTodo = (getthemeDetails) => ({
  type: COLOR_TODO,
  payload: getthemeDetails
});

export const logoTodo = (getthemeLogo) => ({
  type: LOGO_TODO,
  payload: getthemeLogo
});

export const checkTodo = (getChecked) => ({
  type: CHECK_TODO,
  payload: getChecked
});
