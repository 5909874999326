export const COLOR_TODO = 'COLOR_TODO';
export const LOGO_TODO = 'LOGO_TODO';
export const CHECK_TODO = 'CHECK_TODO';

const initialState = {
  color: '',
  logo: '',
  transparent: ''
};

const customReducer = (state = initialState, action) => {
  switch (action.type) {
    case COLOR_TODO:
      return {
        ...state,
        color: action.payload
      };

    case LOGO_TODO:
      return {
        ...state,
        logo: action.payload
      };

    case CHECK_TODO:
      return {
        ...state,
        transparent: action.payload
      };

    default:
      return state;
  }
};

export default customReducer;
