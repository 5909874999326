import { BrowserRouter as Router } from "react-router-dom";
import { ContextApi } from "./Contexts/ContextApi";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import RouterContainer from "./routes/Index";
import TopScroll from "./Common/topScroll";
import SnackbarProviderWrap from "./SnackbarProvider";
import axios from "axios";
import { useDispatch } from 'react-redux';
import { ThemeProvider, createTheme } from "@material-ui/core";
import "./app.css";
import { logoTodo,addTodo,checkTodo } from "./redux/slices/custom/action";

const theme = createTheme({
  palette: {
    primary: {
      main: '#4d7dfa',
    },
  },
});

function App() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [loggedIn, setLoggedIn] = React.useState(false);
  const [user, setUser] = useState(null);
  const [adminLoginMsg, setAdminLoginMsg] = useState("");

  const getLogo = async () => {
    let result;
    try {
      const response = await axios({
        method: "get",
        url: "/theme/"
      });
      result = response?.data;
      if (result?.status===200) {
        const { logo, color: colorT, is_transparent } = result?.data;
        dispatch(logoTodo(logo));
        dispatch(addTodo(colorT));
        dispatch(checkTodo(is_transparent));
      }
    } catch (error) {
      console.log({err:error?.message})
    }
  };

  useEffect(()=>{
    getLogo();
  },[])

  return (
    <>
      <ContextApi.Provider
        value={{
          t,
          i18n,
          user,
          setUser,
          setLoggedIn,
          loggedIn,
          adminLoginMsg,
          setAdminLoginMsg,
        }}
      >
        {/* <PersistGate> */}
        <SnackbarProviderWrap>
          <ThemeProvider theme={theme}>
            <Router>
              <TopScroll />
              <RouterContainer />
            </Router>
          </ThemeProvider>
        </SnackbarProviderWrap>
        {/* </PersistGate> */}
      </ContextApi.Provider>
    </>
  );
}

export default App;
